// extracted by mini-css-extract-plugin
export var wrapper = "hero-module--wrapper--hqudj";
export var gastrofestWrapper = "hero-module--gastrofestWrapper--g5Fjm";
export var gastrofestDate = "hero-module--gastrofestDate--kWGKa";
export var gastrofestTitle = "hero-module--gastrofestTitle--YuI83";
export var gastrofestWrapperMobile = "hero-module--gastrofestWrapperMobile--x22mb";
export var gastrofestWrapperMobileTop = "hero-module--gastrofestWrapperMobileTop--1jbJQ";
export var gastrofestMobileDate = "hero-module--gastrofestMobileDate--maGkW";
export var gastrofestMobileTitle = "hero-module--gastrofestMobileTitle---v8VQ";
export var wrapperTransparent = "hero-module--wrapperTransparent--uGySA hero-module--wrapper--hqudj";
export var listColor = "hero-module--listColor--7ssse hero-module--list--GwvWQ";
export var list = "hero-module--list--GwvWQ";
export var listItem = "hero-module--listItem--ZSOaL";
export var listItemHighlight = "hero-module--listItemHighlight---mXce hero-module--listItem--ZSOaL";
export var imgWrapper = "hero-module--imgWrapper--CNUDk";
export var background = "hero-module--background--CWFOe";
export var slider__slide = "hero-module--slider__slide--xAefi";
export var slider__content = "hero-module--slider__content--V6C-a";
export var slider = "hero-module--slider--LJHmK";