import React, {useEffect, useRef, useState} from 'react'
import {Link} from 'gatsby'
import {Carousel, Grid} from 'antd'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import parse from 'html-react-parser'

import * as styles from './hero.module.scss'

const Hero = props => {
    const backgroundSlider = useRef(null)
    const contentSlider = useRef(null)
    const {heroTitle, heroCta, heroFeatures, heroBackground, heroBackgroundMob, isGastrofest,isStaticGastrofest, gastrofestDate, heroSlider} = props.data

    const {useBreakpoint} = Grid
    const {lg, sm} = useBreakpoint()

    const backgroundImage = sm ?
        heroBackground ? getImage(heroBackground.localFile) : null
        :
        heroBackgroundMob ? getImage(heroBackgroundMob.localFile) : null

    return (
        <section className={isGastrofest ? styles.wrapperTransparent : styles.wrapper}>
            {isGastrofest ? (
                <>
                    {heroSlider && heroSlider.length > 0 ? (
                        <>
                            <Carousel
                                asNavFor={backgroundSlider.current}
                                ref={contentSlider}
                                arrows={sm}
                                dots={true}
                                fade={true}
                                draggable={true}
                                slidesToShow={1}
                                slidesToScroll={1}
                                easing='cubic-bezier(0.65, 0.05, 0.36, 1)'
                                className={styles.slider}
                            >
                                {heroSlider.map((slide, index) => {
                                    return (
                                        <div key={index} className={styles.slider__slide}>
                                            {slide.heroSlideTitle && (
                                                <h2 className='global-title-h1'>{parse(slide.heroSlideTitle)}</h2>
                                            )}
                                            {slide.heroSlideContent && (
                                                <p className={styles.slider__content}>
                                                    {parse(slide.heroSlideContent)}
                                                </p>
                                            )}
                                            {slide.heroSlideLink && slide.heroSlideLink.url && (
                                                <Link
                                                    to={slide.heroSlideLink.url}
                                                    className='global-button'
                                                    {...(slide.heroSlideLink.target && {
                                                        target: slide.heroSlideLink.target,
                                                    })}
                                                >
                                                    {parse(slide.heroSlideLink.title)}
                                                </Link>
                                            )}
                                        </div>
                                    )
                                })}
                            </Carousel>
                            <div className={styles.background}>
                                <Carousel
                                    asNavFor={contentSlider.current}
                                    ref={backgroundSlider}
                                    arrows={false}
                                    dots={false}
                                    // fade={true}
                                    lazyLoad={true}
                                    slidesToShow={1}
                                    slidesToScroll={1}
                                    easing='cubic-bezier(0.65, 0.05, 0.36, 1)'
                                    className={styles.slider}
                                >
                                    {heroSlider.map((slide, index) => {
                                        const slideImage =
                                            slide.heroSlideImage && slide.heroSlideImage.localFile
                                                ? getImage(slide.heroSlideImage.localFile)
                                                : null

                                        return (
                                            <div key={index}>
                                                {typeof slideImage === 'object' && slideImage !== null && (
                                                    <GatsbyImage
                                                        style={{overflow: 'visible'}}
                                                        imgStyle={
                                                            sm && {
                                                                zIndex: '2',
                                                                width: 'auto',
                                                                height: 'auto',
                                                                minHeight: !lg ? `860px` : `765px`,
                                                                left: `50%`,
                                                                transform: `translateX(-50%)`,
                                                            }
                                                        }
                                                        loading={sm ? 'lazy' : 'eager'}
                                                        image={slideImage}
                                                        alt='Slide image'
                                                    />
                                                )}
                                            </div>
                                        )
                                    })}
                                </Carousel>
                            </div>
                        </>
                    ) : (
                        <div className='global-container'/>
                    )}
                    {heroFeatures && heroFeatures.length > 0 && (
                        <div className='global-container'>
                            <dl className={styles.listColor}>
                                {heroFeatures.map((item, index) => {
                                    const {heroFeaturesTitle, heroFeaturesText, heroFeaturesIcon, isHighlighting} =
                                        item
                                    const iconImage = heroFeaturesIcon ? getImage(heroFeaturesIcon.localFile) : null

                                    return (
                                        <div
                                            key={index}
                                            className={isHighlighting ? styles.listItemHighlight : styles.listItem}
                                        >
                                            {heroFeaturesTitle && <dt>{parse(heroFeaturesTitle)}</dt>}
                                            {heroFeaturesIcon && (
                                                <div className={styles.imgWrapper}>
                                                    <img
                                                        width={heroFeaturesIcon.mediaDetails.width}
                                                        height={heroFeaturesIcon.mediaDetails.height}
                                                        src={heroFeaturesIcon.localFile.publicURL}
                                                        alt={
                                                            heroFeaturesTitle
                                                                ? heroFeaturesTitle
                                                                : heroFeaturesText
                                                                ? heroFeaturesText
                                                                : 'Hero small image'
                                                        }
                                                        loading='lazy'
                                                    />
                                                </div>
                                            )}
                                            {heroFeaturesText && <dd>{parse(heroFeaturesText)}</dd>}
                                            {/*{typeof iconImage === 'object' && iconImage !== null && (*/}
                                            {/*    <GatsbyImage image={iconImage} alt={heroFeaturesTitle} />*/}
                                            {/*)}*/}
                                        </div>
                                    )
                                })}
                            </dl>
                        </div>
                    )}
                </>
            ) : (
                <>
                    <div className='global-container'>
                        {isStaticGastrofest ? (
                            sm ? (
                                <div className={styles.gastrofestWrapper}>
                                    <div className={styles.gastrofestDate}>{parse(gastrofestDate)}</div>
                                    {heroTitle &&
                                    <h1 className={isStaticGastrofest ? styles.gastrofestTitle + ' global-title-h1' : 'global-title-h1'}>{parse(heroTitle)}</h1>}
                                </div>
                                ) : (
                                <h1 className='global-title-h1'>{parse('ХИНКАЛЬНЯ <br> грузинский ресторан в Гродно')}</h1>
                            )

                        ) : (
                            <>
                                {heroTitle && <h1 className='global-title-h1'>{parse(heroTitle)}</h1>}
                            </>
                        )}

                        {heroCta && heroCta.url && (
                            <Link
                                to={heroCta.url}
                                className='global-button hero-button'
                                {...(heroCta.target && {target: heroCta.target})}
                            >
                                {parse(heroCta.title)}
                            </Link>
                        )}
                        {heroFeatures && heroFeatures.length > 0 && (
                            <dl className={styles.list}>
                                {heroFeatures.map((item, index) => {
                                    const {heroFeaturesTitle, heroFeaturesText, heroFeaturesIcon, isHighlighting} =
                                        item
                                    const iconImage = heroFeaturesIcon ? getImage(heroFeaturesIcon.localFile) : null

                                    return (
                                        <div
                                            key={index}
                                            className={isHighlighting ? styles.listItemHighlight : styles.listItem}
                                        >
                                            {heroFeaturesTitle && <dt>{parse(heroFeaturesTitle)}</dt>}
                                            {heroFeaturesIcon && (
                                                <div className={styles.imgWrapper}>
                                                    <img
                                                        width={heroFeaturesIcon.mediaDetails.width}
                                                        height={heroFeaturesIcon.mediaDetails.height}
                                                        src={heroFeaturesIcon.localFile.publicURL}
                                                        alt={
                                                            heroFeaturesTitle
                                                                ? heroFeaturesTitle
                                                                : heroFeaturesText
                                                                ? heroFeaturesText
                                                                : 'Hero small image'
                                                        }
                                                        loading='lazy'
                                                    />
                                                </div>
                                            )}
                                            {heroFeaturesText && <dd>{parse(heroFeaturesText)}</dd>}
                                            {/*{typeof iconImage === 'object' && iconImage !== null && (*/}
                                            {/*    <GatsbyImage image={iconImage} alt={heroFeaturesTitle} />*/}
                                            {/*)}*/}
                                        </div>
                                    )
                                })}
                            </dl>
                        )}
                    </div>
                    {typeof backgroundImage === 'object' && backgroundImage !== null && (
                        <div className={styles.background}>
                            {isStaticGastrofest && (
                                    !sm &&
                                    <div className={styles.gastrofestWrapperMobile}>
                                        <div className={styles.gastrofestWrapperMobileTop}>
                                            <div className={styles.gastrofestMobileDate}>{parse(gastrofestDate)}</div>
                                            {heroTitle &&
                                            <h1 className={styles.gastrofestMobileTitle + ' global-title-h1'}>{parse(heroTitle)}</h1>}
                                        </div>
                                        {heroCta && heroCta.url && (
                                            <Link
                                                to={heroCta.url}
                                                className='global-button hero-button'
                                                {...(heroCta.target && {target: heroCta.target})}
                                            >
                                                {parse(heroCta.title)}
                                            </Link>
                                        )}

                                    </div>
                                )}
                            <GatsbyImage
                                style={{overflow: 'visible'}}
                                imgStyle={{
                                    zIndex: '2',
                                    // width: 'auto',
                                    // height: 'auto',
                                    minHeight: !sm ? 'none' : !lg ? `860px` : `765px`,
                                    left: !sm ? `0` : `50%`,
                                    transform: !sm ? `translateX(0)` : `translateX(-50%)`,
                                }}
                                loading={sm ? 'lazy' : 'eager'}
                                image={backgroundImage}
                                alt='Hero image'
                            />
                        </div>
                    )}
                </>
            )}
        </section>
    )
}

export default Hero
