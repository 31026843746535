import React, {useEffect, useRef} from 'react'

import CloseIcon from '../../assets/svg/close.inline.svg'
import * as styles from './modal.module.scss'

const Modal = props => {
    const { title, visible, onOk, onCancel, children, className, actionScroll } = props

    const enableScroll = useRef(null)

    useEffect(()=>{
        enableScroll.current.scrollTo(0, 500);
    },[actionScroll])

    return (
        <div className={(visible ? styles.modal : styles.modalHidden) + ' ' + className}>
            <div className={styles.outline} onClick={() => onCancel()} />
            <div className={styles.wrapper} ref={enableScroll}>
                {title && <h3 className={styles.title}>{title}</h3>}
                <button className={styles.close} onClick={() => onCancel()}>
                    Close
                    <CloseIcon />
                </button>
                {children}
            </div>
        </div>
    )
}

export default Modal
