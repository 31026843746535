import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import ContactUs from '../components/ContactUs'

const ContactsPage = ({data, isLang}) => {
    const contactSchema = `
    {
  "@context": "https://schema.org",
  "@type": "Restaurant",
  "name": "Хинкальня",
"description": "У нас больше чем рецепты, это кулинарная история Грузии в вашей тарелке. Собирайте друзей за большим и уютным столом “Хинкальни” в Гродно.",
  "image": "https://hinkalnyagrodno.by/static/47fee33db0a9d685340dacd96d30e6a9/87757/mobilehero.jpg",
  "@id": "https://hinkalnyagrodno.by/contacts",
  "url": "https://hinkalnyagrodno.by/",
  "telephone": "+375 44 763-59-59",
  "priceRange": "$$",
  "menu": "https://hinkalnyagrodno.by/menu",
  "servesCuisine": "Грузинская",
  "acceptsReservations": "true",
  "address": {
    "@type": "PostalAddress",
    "streetAddress": "Урицкого 5",
    "addressLocality": "Гродно",
    "postalCode": "230025",
    "addressCountry": "BY"
  },
  "geo": {
    "@type": "GeoCoordinates",
    "latitude": 53.681394,
    "longitude": 23.8326761
  },
  "openingHoursSpecification": [{
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Sunday"
    ],
    "opens": "12:00",
    "closes": "23:00"
  },{
    "@type": "OpeningHoursSpecification",
    "dayOfWeek": [
      "Friday",
      "Saturday"
    ],
    "opens": "12:00",
    "closes": "00:00"
  }],
  "sameAs": [
    "https://www.instagram.com/hinkalnya.grodno/",
    "https://www.tripadvisor.ru/Restaurant_Review-g661665-d21012839-Reviews-Hinkalnya_Grodno-Grodno_Grodno_Region.html",
    "https://www.facebook.com/hinkalnya.grodno/"
  ] 
}

    `;
    return (
        <>
            <script type='application/ld+json' dangerouslySetInnerHTML={{__html: contactSchema}}/>
            <ContactUs data={data} isLang={isLang}/>
        </>
    )
}

export default ContactsPage
