import React from 'react'
import {Link} from 'gatsby'
import {Grid, Tabs} from 'antd'

import * as styles from './product.module.scss'
import {GatsbyImage, getImage} from "gatsby-plugin-image";

const ProductCategories = ({mode, container, categories, activeCategoryId, onSelect, isLang}) => {
    const {TabPane} = Tabs
    const {useBreakpoint} = Grid
    const {sm} = useBreakpoint()

    const linkMenu = isLang ? '/menu' : '/en/menu';
    let labelMenu = isLang ? 'Меню' : 'Menu'
    const renderProductCategory = ({category}, isIconBefore) => {
        const linkAttr = {}
        const catProps = category.categorySettings;
        const newIcon = category.categorySettings.newToggler ? category.categorySettings.newToggler : false;
        const image = catProps.image ? catProps.image.localFile : null
        const altText = catProps.image.altText;
        return (
            <Link
                to={`${linkMenu}/#${category.id}`}
                className={styles.categoriesHome__listItemLink}
            >
                {image.publicURL && (
                    <img
                        className={styles.categories__linkImg}
                        src={image.publicURL}
                        loading='lazy'
                        alt={altText ? altText : category.name}
                    />
                )}
                {/*{newIcon && (<sup>New</sup>)}*/}
                <span>{category.name}</span>
            </Link>
        )
    }

    return (
        <div className='global-container'>
            <h2 className={styles.productSliderTitle + ' global-title-h2'}>{labelMenu}</h2>
        <ul className={styles.categoriesHome__list}>
            {categories.map(category => category.categorySettings.showHome && (
                <li key={category.id} className={styles.categoriesHome__listItem}>
                    {renderProductCategory({category}, false)}
                </li>
            ))}
        </ul>
        </div>
    )
}

export default ProductCategories
