import React from 'react'
import parse from 'html-react-parser'
import {getImage } from 'gatsby-plugin-image'
import IconGift from '../../assets/svg/iconGift.inline.svg'
import IconSale from '../../assets/svg/iconSale.inline.svg'
import * as styles from './stocks.module.scss'

const Stocks = props => {
    const {stocksTitle,stocksList} = props.data

    return (
        <section className={styles.wrapperInner}>
            <div className='global-container'>
                {stocksTitle && <h1 className="global-title-h2">{parse(stocksTitle)}</h1>}
                {stocksList.length > 0 &&(
                    <ul className={styles.stocksList}>
                        {
                            stocksList.map((item, index) =>{
                                return (
                                    <li key={index} className={styles.stocksItem}>
                                        <div className={styles.stocksItemIcon}>{item.type === 'gift' ? <IconGift /> : <IconSale />}</div>
                                        <h2 className={styles.title}>{item.title}</h2>
                                        {item.description && (<div className={styles.desc}>{item.description}</div>)}
                                        {item.notes && (<div className={styles.notes}>* - {item.notes.replace('-', '')}</div>)}
                                    </li>
                                )


                            })
                        }
                    </ul>
                )}
            </div>
        </section>
    )
}

export default Stocks
