// extracted by mini-css-extract-plugin
export var wrapperInner = "gastrofest-module--wrapperInner--CLox6";
export var title = "gastrofest-module--title--82Bda";
export var iconList = "gastrofest-module--iconList--bWdXu";
export var description = "gastrofest-module--description--VhzY-";
export var setImage = "gastrofest-module--setImage--qFK0q";
export var setNamePosition = "gastrofest-module--setNamePosition--JmZhN";
export var iconListItem = "gastrofest-module--iconListItem--QhgNB";
export var sliderWrapper = "gastrofest-module--sliderWrapper--LDfQs";
export var mobImage = "gastrofest-module--mobImage--Z8Q6W";
export var setInfo = "gastrofest-module--setInfo--oJ9yl";
export var setInfoWeight = "gastrofest-module--setInfoWeight--aBxh+";
export var setInfoParts = "gastrofest-module--setInfoParts--w+1X9";
export var setInfoPartsItem = "gastrofest-module--setInfoPartsItem--us2II";
export var specialDeals = "gastrofest-module--specialDeals--j0p0s";
export var specialDealsList = "gastrofest-module--specialDealsList--irVVa";
export var workTime = "gastrofest-module--workTime--7ENNJ";
export var workTimeList = "gastrofest-module--workTimeList--vDq+Y";
export var workTimeItem = "gastrofest-module--workTimeItem--tINFj";
export var workTimeNote = "gastrofest-module--workTimeNote--1ZrkF";
export var bottomInfo = "gastrofest-module--bottomInfo--Ga8gf";
export var bottomInfoTop = "gastrofest-module--bottomInfoTop--Bk3ak";
export var bottomInfoPrice = "gastrofest-module--bottomInfoPrice--nU2Ia";
export var bottomInfoNote = "gastrofest-module--bottomInfoNote--np2Bz";
export var bottomNote = "gastrofest-module--bottomNote--8VshY";