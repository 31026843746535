import React from 'react'
import {graphql} from 'gatsby'

import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import HomePage from '../HomePage'
import DeliveryPage from '../DeliveryPage'
import GastrofestPage from '../GastrofestPage'
import StocksPage from '../StocksPage'
import ContactsPage from '../ContactsPage'
import ReviewsPage from "../ReviewsPage";

const page = ({data, pageContext, location}) => {
    const {page} = data
    const {
        content,
        featuredImage,
        databaseId,
        uri,
        seo,
        isFrontPage,
        template: {templateName},

    } = page
    const {title} = seo;
    const pageTitle = page.title;
    let templateComponent = null;
    let isGoogleMapsInFooterPromo = false;
    let isFooterPromo = isFrontPage;
    let isHideText = false;
    let isGoogleMaps = isFrontPage ? isFrontPage : false;
    let isGoogleMapsOnMobile = false;
    let isHideOnDesktop = false;

    switch (templateName) {
        case 'Delivery':
            isGoogleMapsInFooterPromo = true;
            isFooterPromo = false;
            isHideText = false;
            templateComponent = <DeliveryPage data={page} isLang={pageContext.lang}/>
            break;
        case 'Gastrofest':
            isGoogleMapsInFooterPromo = false;
            isFooterPromo = true;
            isHideText = true;
            templateComponent = <GastrofestPage data={page} title={pageTitle} isLang={pageContext.lang}/>
            break;
        case 'Stocks':
            isGoogleMapsInFooterPromo = true;
            isFooterPromo = true;
            isHideText = true;
            templateComponent = <StocksPage data={page} isLang={pageContext.lang}/>
            break;

        case 'Contact Us':
            isGoogleMapsInFooterPromo = false;
            isHideText = false;
            isFooterPromo = false;
            isGoogleMaps = true;
            isGoogleMapsOnMobile = true;
            templateComponent = <ContactsPage data={page} isLang={pageContext.lang}/>
            break;
        case 'Reviews':
            isGoogleMapsInFooterPromo = true;
            isFooterPromo = true;
            isHideText = true;
            templateComponent = <ReviewsPage data={page} isLang={pageContext.lang}/>
            break;
        default:
            templateComponent = (
                <article className='global-container' id={`post-${databaseId}`}>
                    <h1 className='global-title-h1' dangerouslySetInnerHTML={{__html: pageTitle}}/>

                    <div className='post-inner'>
                        <div className='post-content' dangerouslySetInnerHTML={{__html: content}}/>
                    </div>
                </article>
            )
    }

    console.log('...page data start...')
    // console.log(data)
    // console.log(location)
    console.log('...page data end...')
    return (
        <Layout
            isHeaderTransparent={isFrontPage}
            isFooterPromo={isFooterPromo}
            isHideText={isHideText}
            isGoogleMaps={isGoogleMaps}
            isGoogleMapsOnMobile={isGoogleMapsOnMobile}
            isLang={pageContext.lang}
            isGoogleMapsInFooterPromo={isGoogleMapsInFooterPromo}
            isHideOnDesktop={isHideOnDesktop}>
            <Seo title={title ? title : page.title} meta={seo} uri={uri}/>

            {isFrontPage ? <HomePage data={page} isLang={pageContext.lang}/> : templateComponent}
        </Layout>
    )
}

export const query = graphql`
    query page($id: String!, $nextPage: String, $previousPage: String) {
        page: wpPage(id: { eq: $id }) {
            ...PageContent
        }
        nextPage: wpPage(id: { eq: $nextPage }) {
            title
            uri
        }
        previousPage: wpPage(id: { eq: $previousPage }) {
            title
            uri
        }
    }
`

export default page


