import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Stocks from '../components/Stocks'

const StocksPage = ({data, isLang}) => {
    const {stocksSection} = data
    return (
        <>
            <Stocks data={stocksSection}/>
        </>
    )
}

export default StocksPage
