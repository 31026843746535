import React from 'react'

import loadable from "@loadable/component";
import { QueryClient, QueryClientProvider } from "react-query";
const queryClient = new QueryClient();
const DeliveryHome = loadable(() => import('./DeliveryHome'))
const DeliveryInner = loadable(() => import('./DeliveryInner'))

const Delivery = props => {
    return props.isInner ?
            <DeliveryInner {...props} />: <DeliveryHome {...props}/>
}

export default Delivery
