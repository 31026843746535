import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Gastrofest from '../components/Gastrofest'

const GastrofestPage = ({data, title, isLang}) => {
    const {gastrofest} = data

    return (
        <>
            <Gastrofest data={gastrofest} title={title} isInner={true}/>
        </>
    )
}

export default GastrofestPage
