import React, {useEffect, useState} from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import * as styles from "../components/Reviews/reviews.module.scss";
import ReviewsList from "../components/Reviews/ReviewsList";
const REVIEW_QUERY = `
    {
        reviews(
        first: 100, 
        where: {reviewIsShowAtHomePage: true, orderby: {field: DATE, order: ASC}}) {
            nodes {
              id
              title
              content
              review {
                reviewIsShowAtHomePage
                reviewIsShowAtMobile
                reviewLikesCount
                reviewRate
                reviewSource
              }
            }
        }
    }
`;
const ReviewsPage = ({data, isLang}) => {
    const {title} = data
    const [loadReview, setLoadReview] = useState(null);
    // const {reviewsTitle, reviewsLink, reviewsServices} = reviewsSection.data
    // const {homePageReviews} = useStaticQuery(
    //     graphql`
    //         query {
    //             homePageReviews: allWpReview(
    //                 sort: { fields: date }
    //                 filter: { review: { reviewIsShowAtHomePage: { eq: true } } }
    //             ) {
    //                 nodes {
    //                     ...Review
    //                 }
    //             }
    //         }
    //     `
    // )

    useEffect(() => {
        fetch('https://hinkalnyagrodno.dizzain.com/graphql', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({query: REVIEW_QUERY})
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Error fetching data");
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                setLoadReview(() => data.data);
            });

    }, []);

    return (
        <>
            <section className={styles.wrapperInner}>
                <div className='global-container'>
                    <h2 className='global-title-h2'>{title}</h2>
                    <div className={styles.row}>
                        {loadReview && (
                            <div className={styles.row}>
                                <ReviewsList nodes={loadReview.reviews.nodes} inner={true}/>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    )
}

export default ReviewsPage
