import React, {useEffect, useState,useRef} from 'react'
import parse from 'html-react-parser'
import { Radio, Button, Rate } from 'antd'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage, StaticImage } from 'gatsby-plugin-image'

import Form from '../Form'
import Modal from '../Modal'
import ReviewsList from './ReviewsList'
import Star from '../../assets/svg/star.inline.svg'
import * as styles from './reviews.module.scss'
const REVIEW_QUERY = `
    {
        reviews(
        first: 100, 
        where: {reviewIsShowAtHomePage: true, orderby: {field: DATE, order: ASC}}) {
            nodes {
              id
              title
              content
              review {
                reviewIsShowAtHomePage
                reviewIsShowAtMobile
                reviewLikesCount
                reviewRate
                reviewSource
              }
            }
        }
    }
`;
const Reviews = props => {
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedRestaurant, setSelectedRestaurant] = useState(null);
    const [selectedRate, setSelectedRate] = useState(0);
    const [scrollWrapper, setScrollWrapper] = useState(false);
    const [loadReview, setLoadReview] = useState(null);
    const childInputRef = useRef(null)
    const { reviewsTitle, reviewsLink, reviewsServices } = props.data;
    // const { homePageReviews } = useStaticQuery(
    //     graphql`
    //         query {
    //             homePageReviews: allWpReview(
    //                 sort: {fields: date}
    //                 filter: { review: { reviewIsShowAtHomePage: { eq: true } } }
    //             ) {
    //                 nodes {
    //                     ...Review
    //                 }
    //             }
    //         }
    //     `
    // )

    const isLang = props.isLang;

    const messageReviewProblemQuestion = isLang ? 'Что-то было не так?' : 'Was there something wrong?';
    const messageReviewProblemAbout = isLang ? 'Расскажите об этом здесь, чтобы мы могли отследить и решить возможные проблемы' : 'Tell us about it here so we can track and solve possible problems';
    const addressFirst = isLang ? 'Хинкальня на ул. Урицкого, 5' : 'Khinkalnya on Uritskogo St., 5';
    const addressSecond = isLang ? 'Хинкальня на ул. Я.Купалы, 87 (ТРК Тринити)' : 'Hinkalnya at 87 J. Kupala St. (Trinity Shopping Mall)';
    const messageEvaluation = isLang ? 'Ваша оценка' : 'Your assessment';
    const messageFormSubmittedFirst = isLang ? 'Спасибо за ваше мнение!' : 'Thank you for your opinion!';
    const messageFormSubmittedSecond = isLang ? 'В каком ресторане вы были?' : 'What restaurant have you been to?';
    const messageReturnOnHome = isLang ? 'Вернуться на главную' : 'Go back to the main page';
    const messageOpenMenu = isLang ? 'Открыть меню' : 'Open menu';
    const linkMenu = isLang ? '/menu' : '/en/menu';

    useEffect(() => {
        fetch('https://hinkalnyagrodno.dizzain.com/graphql', {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({query: REVIEW_QUERY})
        })
            .then((response) => {
                if (response.status >= 400) {
                    throw new Error("Error fetching data");
                } else {
                    return response.json();
                }
            })
            .then((data) => {
                setLoadReview(() => data.data);
            });

    }, [])


    const showModal = () => {
        setIsModalVisible(true)
    }

    const handleOk = () => {
        setIsModalVisible(false)
    }

    const handleCancel = () => {
        setIsModalVisible(false)
    }

    const handleRestaurantChange = e => {
        setSelectedRestaurant(e.target.value)
        setSelectedRate(0);
    }

    const handleRateChange = value => {
        setSelectedRate(value);
        if(value < 4){
            setScrollWrapper(!scrollWrapper);
        }
    };

    const handleLiftSubmitted = bool => {
        setIsFormSubmitted(bool)
    }

    return (
        <section className={styles.wrapper}>
            <div className='global-container'>
                <h2 className='global-title-h2'>{reviewsTitle}</h2>
                <div className={styles.row}>
                        {reviewsServices && reviewsServices.length > 0 && (
                            <ul className={styles.services}>
                                {reviewsServices.map(item => {
                                    const {
                                        reviewsServicesLink,
                                        reviewsServicesIcon,
                                        reviewsServicesTotal,
                                        reviewsServicesRate,
                                    } = item
                                    const iconImage = reviewsServicesIcon ? getImage(reviewsServicesIcon.localFile) : null

                                    return (
                                        <li className={styles.servicesItem} key={reviewsServicesLink.url}>
                                            <a
                                                href={reviewsServicesLink.url}
                                                {...(reviewsServicesLink.target && { target: reviewsServicesLink.target })}
                                            >
                                                <span style={{ fontSize: 0 }}>{reviewsServicesLink.title}</span>
                                                {typeof iconImage === 'object' && iconImage !== null && (
                                                    <div className={styles.servicesItemIcon}>
                                                        <GatsbyImage image={iconImage} alt='Hero image' />
                                                        <Rate disabled allowHalf defaultValue={5} style={{ fontSize:18, color: '#FFC700' }} />
                                                    </div>
                                                )}
                                                <div className={styles.servicesItemRate}>
                                                    <b>{parse(reviewsServicesRate)}</b>
                                                    <span>{parse(reviewsServicesTotal)}</span>
                                                </div>
                                            </a>
                                        </li>
                                    )
                                })}
                                <li className={styles.servicesItem}>
                                    <Button type='primary' className='global-button button-tablet' onClick={showModal}>
                                        {reviewsLink.title}
                                    </Button>
                                </li>
                            </ul>
                        )}
                    <Modal
                        title={isFormSubmitted ? messageFormSubmittedFirst : messageFormSubmittedSecond}
                        visible={isModalVisible}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        className={isFormSubmitted ? styles.modalThanks : ''}
                        actionScroll={scrollWrapper}
                    >
                        {isFormSubmitted ? (
                            <div className={styles.modalThanks}>
                                <StaticImage src='../../assets/images/dancing-man.jpg' alt='Thank you!' />
                                <button className='global-button' onClick={() => handleCancel()}>
                                    {messageReturnOnHome}
                                </button>
                                <a className='global-button global-button--text' href={linkMenu}>
                                    {messageOpenMenu}
                                </a>
                            </div>
                        ) : (
                            <div>
                                <Radio.Group
                                    onChange={handleRestaurantChange}
                                    value={selectedRestaurant}
                                    className={styles.modalForm}
                                >
                                    <Radio.Button value='a'>{addressFirst}</Radio.Button>
                                    <Radio.Button value='b'>{addressSecond}</Radio.Button>
                                </Radio.Group>
                                {selectedRestaurant ? (
                                    <div className={styles.modalRate}>
                                        <h5 className={styles.modalTitle}>{messageEvaluation}</h5>
                                        <Rate onChange={handleRateChange} value={selectedRate} character={<Star />} />
                                    </div>
                                ) : null}
                                {(selectedRate && selectedRestaurant === 'b') ||
                                (selectedRate >= 4 && selectedRestaurant === 'a') ? (
                                    <div>
                                        <h3 className={styles.modalTitle}>
                                            {selectedRate >= 4 && selectedRestaurant === 'a'
                                                ? 'Вах! Как мы рады, что вам было вкусно и уютно! Вы можете оставить свой отзыв на удобном для вас сервисе'
                                                : 'Выберите удобный сервис, чтобы оставить отзыв на ресторан Хинкальня в ТРК Тринити'}
                                        </h3>
                                        <ul className={selectedRestaurant == 'a' ? styles.modalServices : styles.modalServices + ' ' + styles.modalServicesShort} style={{ width: `100%` }}>
                                            {reviewsServices.map(item => {
                                                const {
                                                    reviewsServicesLink,
                                                    reviewsServicesLinkSecond,
                                                    reviewsServicesIcon,
                                                    reviewsServicesTotal,
                                                    reviewsServicesRate,
                                                } = item
                                                const iconImage = reviewsServicesIcon
                                                    ? getImage(reviewsServicesIcon.localFile)
                                                    : null

                                                if (selectedRestaurant === 'b' && reviewsServicesLink.title === 'Carte') return null

                                                const dataLink = selectedRestaurant === 'a' ?reviewsServicesLink : reviewsServicesLinkSecond;

                                                return (
                                                    <li className={styles.servicesItem} key={dataLink.url}>
                                                        <a
                                                            href={dataLink.url}
                                                            {...(dataLink.target && {
                                                                target: dataLink.target,
                                                            })}
                                                        >
                                                            <span style={{ fontSize: 0 }}>
                                                                {dataLink.title}
                                                            </span>
                                                            {typeof iconImage === 'object' && iconImage !== null && (
                                                                <div className={styles.servicesItemIcon}>
                                                                    <GatsbyImage image={iconImage} alt='Hero image' />
                                                                    {selectedRestaurant === 'a' && (
                                                                        <Rate disabled allowHalf defaultValue={5} style={{ fontSize:18, color: '#FFC700' }} />
                                                                    )}

                                                                </div>
                                                            )}
                                                            {selectedRestaurant == 'a' && (
                                                                <div className={styles.servicesItemRate}>
                                                                    <b>{parse(reviewsServicesRate)}</b>
                                                                    <span>{parse(reviewsServicesTotal)}</span>
                                                                </div>
                                                            )}

                                                        </a>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                ) : null}
                                <div
                                    style={
                                        selectedRate && selectedRate < 4 && selectedRestaurant === 'a'
                                            ? { display: 'block' }
                                            : { display: 'none' }
                                    }
                                >
                                    <h3 className={styles.modalTitle}>
                                        {messageReviewProblemQuestion}
                                        <br />
                                        {messageReviewProblemAbout}
                                    </h3>
                                    <Form
                                        formId='review'
                                        formType='review'
                                        formName='review-form'
                                        handleLiftSubmitted={handleLiftSubmitted}
                                        isLang={isLang}
                                        selectedRate={selectedRate}
                                    />
                                </div>
                            </div>
                        )}
                    </Modal>
                </div>
                <div className={styles.row}>

                    {loadReview && (
                        <ReviewsList nodes={loadReview.reviews.nodes} />
                    )}

                </div>
            </div>
        </section>
    )
}

export default Reviews
