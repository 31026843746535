import React from 'react'
import parse from 'html-react-parser'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import { Grid } from 'antd'

import * as styles from './gastrofest.module.scss'
import ArrowRightIcon from "../../assets/svg/arrow-rightLightButton.inline.svg"
import GastrofestHot from "../../assets/svg/gastrofestHot.inline.svg"
import GastrofestBurger from "../../assets/svg/gastrofestBurger.inline.svg"
import GastrofestChicken from "../../assets/svg/gastrofestChicken.inline.svg"
import GastrofestBread from "../../assets/svg/gastrofestBread.inline.svg"
// import Swiper core and required modules
import { Navigation } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


const Gastrofest = props => {
    console.log(props.data);
    const {address,date,phone,theme,description,note,setDescription, slider} = props.data;
    const title = props.title;
    const { useBreakpoint } = Grid
    const {lg, sm} = useBreakpoint();
    const sliderData = !sm ? slider.slice(1) : slider;
    return (
        <section className={styles.wrapperInner}>
            <div className='global-container'>
                {title && <h1 className="global-title-h1">{parse(title)}</h1>}
                {/*<div className={styles.iconList}>*/}
                {/*    <div className={styles.iconListItem}>*/}
                {/*        <svg width="15" height="21" viewBox="0 0 15 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M7.5 0.5C3.32031 0.5 0 3.85938 0 8C0 11.0469 1.01562 11.9062 6.71875 20.1094C6.91406 20.3828 7.1875 20.5 7.5 20.5C7.77344 20.5 8.04688 20.3828 8.24219 20.1094C13.9453 11.8672 15 11.0469 15 8C15 3.85938 11.6406 0.5 7.5 0.5ZM7.5 19.0156C1.99219 11.125 1.25 10.5 1.25 8C1.25 4.5625 4.02344 1.75 7.5 1.75C10.9375 1.75 13.75 4.5625 13.75 8C13.75 10.5 12.9688 11.0859 7.5 19.0156ZM7.5 4.875C5.74219 4.875 4.375 6.24219 4.375 8C4.375 9.71875 5.74219 11.125 7.5 11.125C9.21875 11.125 10.625 9.71875 10.625 8C10.625 6.24219 9.21875 4.875 7.5 4.875ZM7.5 9.875C6.44531 9.875 5.625 9.01562 5.625 8C5.625 6.94531 6.44531 6.125 7.5 6.125C8.51562 6.125 9.375 6.94531 9.375 8C9.375 9.01562 8.51562 9.875 7.5 9.875Z" fill="#7E7770"/>*/}
                {/*        </svg>*/}

                {/*        {address}</div>*/}
                {/*    <div className={styles.iconListItem}>*/}
                {/*        <svg width="13" height="21" viewBox="0 0 13 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M6.5 17.6875C7.00781 17.6875 7.4375 17.2969 7.4375 16.75C7.4375 16.2422 7.00781 15.8125 6.5 15.8125C5.95312 15.8125 5.5625 16.2422 5.5625 16.75C5.5625 17.2969 5.95312 17.6875 6.5 17.6875ZM10.25 0.5H2.75C1.34375 0.5 0.25 1.63281 0.25 3V18C0.25 19.4062 1.34375 20.5 2.75 20.5H10.25C11.6172 20.5 12.75 19.4062 12.75 18V3C12.75 1.63281 11.6172 0.5 10.25 0.5ZM11.5 18C11.5 18.7031 10.9141 19.25 10.25 19.25H2.75C2.04688 19.25 1.5 18.7031 1.5 18V14.25H11.5V18ZM11.5 13H1.5V3C1.5 2.33594 2.04688 1.75 2.75 1.75H10.25C10.9141 1.75 11.5 2.33594 11.5 3V13Z" fill="#7E7770"/>*/}
                {/*        </svg>*/}
                {/*        <a href={`tel:+${parse(phone.replace(/\D/g, ""))}`}>{parse(phone)}</a>*/}
                {/*    </div>*/}
                {/*    <div className={styles.iconListItem}>*/}
                {/*        <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M17.5 3H16.25V1.125C16.25 0.8125 15.9375 0.5 15.625 0.5C15.2734 0.5 15 0.8125 15 1.125V3H8.75V1.125C8.75 0.8125 8.4375 0.5 8.125 0.5C7.77344 0.5 7.5 0.8125 7.5 1.125V3H6.25C4.84375 3 3.75 4.13281 3.75 5.5V14.25C3.75 15.6562 4.84375 16.75 6.25 16.75H17.5C18.8672 16.75 20 15.6562 20 14.25V5.5C20 4.13281 18.8672 3 17.5 3ZM18.75 14.25C18.75 14.9531 18.1641 15.5 17.5 15.5H6.25C5.54688 15.5 5 14.9531 5 14.25V8H18.75V14.25ZM18.75 6.75H5V5.5C5 4.83594 5.54688 4.25 6.25 4.25H17.5C18.1641 4.25 18.75 4.83594 18.75 5.5V6.75ZM15.625 19.25H3.75C2.34375 19.25 1.25 18.1562 1.25 16.75V7.375C1.25 7.0625 0.9375 6.75 0.625 6.75C0.273438 6.75 0 7.0625 0 7.375V16.75C0 18.8594 1.64062 20.5 3.75 20.5H15.625C15.9375 20.5 16.25 20.2266 16.25 19.875C16.25 19.5625 15.9375 19.25 15.625 19.25Z" fill="#7E7770"/>*/}
                {/*        </svg>*/}

                {/*        {date}*/}
                {/*    </div>*/}
                {/*    <div className={styles.iconListItem}>*/}
                {/*        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
                {/*            <path d="M17.5 14.875V2.375C17.5 1.35938 16.6406 0.5 15.625 0.5H3.125C1.36719 0.5 0 1.90625 0 3.625V18C0 19.4062 1.09375 20.5 2.5 20.5H16.875C17.1875 20.5 17.5 20.2266 17.5 19.875C17.5 19.5625 17.1875 19.25 16.875 19.25H16.25V16.6719C16.9531 16.3984 17.5 15.6953 17.5 14.875ZM1.25 3.625C1.25 2.60938 2.07031 1.75 3.125 1.75H3.75V15.5H2.5C2.03125 15.5 1.60156 15.6562 1.25 15.8516V3.625ZM15 19.25H2.5C1.79688 19.25 1.25 18.7031 1.25 18C1.25 17.3359 1.79688 16.75 2.5 16.75H15V19.25ZM15.625 15.5H5V1.75H15.625C15.9375 1.75 16.25 2.0625 16.25 2.375V14.875C16.25 15.2266 15.9375 15.5 15.625 15.5ZM13.75 5.5H7.5C7.14844 5.5 6.875 5.8125 6.875 6.125C6.875 6.47656 7.14844 6.75 7.5 6.75H13.75C14.0625 6.75 14.375 6.47656 14.375 6.125C14.375 5.8125 14.0625 5.5 13.75 5.5ZM13.75 9.25H7.5C7.14844 9.25 6.875 9.5625 6.875 9.875C6.875 10.2266 7.14844 10.5 7.5 10.5H13.75C14.0625 10.5 14.375 10.2266 14.375 9.875C14.375 9.5625 14.0625 9.25 13.75 9.25Z" fill="#7E7770"/>*/}
                {/*        </svg>*/}

                {/*        {theme}*/}
                {/*    </div>*/}
                {/*</div>*/}
                {!sm && (
                    <div className={styles.mobImage}>
                        <GatsbyImage
                            key={slider[0].image.id}
                            className={styles.setImage}
                            image={slider[0].image && slider[0].image.localFile ? getImage(slider[0].image.localFile) : null}
                            alt={slider[0].image.alt ? slider[0].image.alt : ''}
                        />
                    </div>
                )}
                <div className={styles.description}>{parse(description)}</div>
            </div>
            <div className={styles.sliderWrapper}>
                <Swiper
                    slidesPerView={lg ? 3 : sm ? 2 : 1}
                    spaceBetween={20}
                    centeredSlides={false}
                    loop={true}
                    navigation={{
                        prevEl: '.customNavigationPrev',
                        nextEl: '.customNavigationNext',
                    }}
                    //initialSlide={1}
                    modules={[Navigation]}
                >
                    {
                        sliderData.map((node,index) => {
                        const image = node.image && node.image.localFile ? getImage(node.image.localFile) : null

                        return (
                            <SwiperSlide key={index}>
                                <GatsbyImage
                                    key={node.id}
                                    className={styles.setImage}
                                    image={image}
                                    alt={node.image.alt ? node.image.alt : ''}
                                />
                                <div className={styles.setNamePosition}>{node.name}</div>
                            </SwiperSlide>
                        )
                    })}
                    {
                        <div className="customNavigation">
                            <div className="customNavigationPrev"><ArrowRightIcon/></div>
                            <div className="customNavigationNext"><ArrowRightIcon/></div>
                        </div>
                    }
                </Swiper>
            </div>

            <section className={styles.setInfo}>
                {setDescription.name && <h2 className="global-title-h4">{parse(setDescription.name)}</h2>}
                {setDescription.weight && <div className={styles.setInfoWeight}>{parse(setDescription.weight)}</div>}
                {setDescription.setParts &&
                    <ul className={styles.setInfoParts}>
                        {setDescription.setParts.map((item, index) => {
                            let iconComponent;
                            switch (item.icon) {
                                case 'hot':
                                    iconComponent = <GastrofestHot />;
                                    break;
                                case 'burger':
                                    iconComponent = <GastrofestBurger />;
                                    break;
                                case 'chicken':
                                    iconComponent = <GastrofestChicken />;
                                    break;
                                case 'bread':
                                    iconComponent = <GastrofestBread />;
                                    break;
                                default:
                                    break;
                            }
                            return (
                                <li key={index} className={styles.setInfoPartsItem}>
                                    {iconComponent}
                                    {item.name}
                                </li>
                            );
                        })}
                    </ul>
                }
                {setDescription.isSpecialDeals && (
                    <section className={styles.specialDeals}>
                        {setDescription.specialDealsDetails.title && <h3 className="global-title-h5">{parse(setDescription.specialDealsDetails.title)}</h3>}
                        {setDescription.specialDealsDetails.deals &&
                        <ul className={styles.specialDealsList}>
                            {setDescription.specialDealsDetails.deals.map((item, index) => {
                                return <li key={index}>{parse(item.text)}</li>;

                            })}
                        </ul>
                        }
                    </section>
                )}


                {setDescription.workTime && (
                    <section className={styles.workTime}>
                        {setDescription.workTime.title && <h3 className="global-title-h5">{parse(setDescription.workTime.title)}</h3>}
                        {setDescription.workTime.dates && (
                            <div className={styles.workTimeList}>
                                {
                                    setDescription.workTime.dates.map((item, index) => {
                                        return (
                                            <div key={index} className={styles.workTimeItem}>
                                                <div className={styles.workTimeItemDay}>{parse(item.day)}</div>
                                                <div className={styles.workTimeItemTime}>{parse(item.time)}</div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        )}
                        {setDescription.workTime.note && <div className={styles.workTimeNote}>{parse(setDescription.workTime.note)}</div>}
                    </section>
                )}

                {setDescription.setInfo.title && setDescription.setInfo.price && (
                    <section className={styles.bottomInfo}>
                        <hr/>
                        <div className={styles.bottomInfoTop}>
                            {setDescription.setInfo.title && <h2 className="global-title-h4">{parse(setDescription.setInfo.title)}</h2>}
                            {setDescription.setInfo.price && <div className={styles.bottomInfoPrice}>{parse(setDescription.setInfo.price)}</div>}
                        </div>
                        {setDescription.setInfo.note && <div className={styles.bottomInfoNote}>{parse(setDescription.setInfo.note)}</div>}
                    </section>
                )}

            </section>

            {/*{note && (*/}
            {/*    <div className={styles.bottomNote}>{parse(note)}</div>*/}
            {/*)}*/}




        </section>



    )
}

export default Gastrofest
