import React, {useEffect} from 'react'
import {Grid} from "antd";
import {graphql, useStaticQuery} from 'gatsby'
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image'
import parse from 'html-react-parser'
import * as styles from './contactus.module.scss'
import SVG from "react-inlinesvg";

const ContactUs = ({data, isLang}) => {
    const {useBreakpoint} = Grid;
    const {lg, sm} = useBreakpoint();

    const {allWp} = useStaticQuery(
        graphql`
            {
                allWp {
                    nodes {
                        optionPage {
                            themeOptions {
                                workingTime {
                                    workingTimeTime
                                    workingTimeDays
                                    workingTimeDaysEn
                                }
                                phoneList {
                                    phoneListNumber
                                }
                                socialList {
                                    socialListLink {
                                        url
                                        title
                                        target
                                    }
                                    socialListLinkEn {
                                        url
                                        title
                                        target
                                    }
                                    socialListIcon {
                                        localFile {
                                            publicURL
                                        }
                                        mediaDetails {
                                            width
                                            height
                                        }
                                    }
                                }
                                address
                                addressEn
                                promoTitle
                                promoTitleEn
                                promoContent
                                promoContentEn
                                contactImg {
                                    altText
                                    localFile {
                                        publicURL
                                        childImageSharp {
                                            gatsbyImageData(quality: 90)
                                        }
                                    }
                                    mediaDetails {
                                        width
                                        height
                                    }
                                }
                            }
                        }
                    }
                }
            }

        `
    )

    let labelPhones, labelAddress, labelSocial, labelWeAreOpen;
    if (isLang) {
        labelPhones = 'Телефоны'
        labelAddress = 'Адрес'
        labelSocial = 'Социальные сети'
        labelWeAreOpen = 'Мы открыты'
    } else {
        labelPhones = 'Phones'
        labelAddress = 'Address'
        labelSocial = 'Social media'
        labelWeAreOpen = 'We are open'
    }


    const generalData = allWp.nodes[0].optionPage.themeOptions
    const slideImage = generalData.contactImg && generalData.contactImg.localFile
        ? getImage(generalData.contactImg.localFile)
        : null

    const linkToMap = isLang ? '/contacts/#footerMaps' : '/en/contacts/#footerMaps';


    return (

        <section className={styles.wrapperInner}>

            <div className='global-container'>
                <div className={styles.colLeft}>
                    <h1 className="global-title-h2">{data.title}</h1>
                    <div className={styles.contactWrapper}>
                        <div className={styles.contactCol}>
                            <h2 className='global-title-h5'>{labelWeAreOpen}</h2>
                            {generalData &&
                            generalData.workingTime &&
                            generalData.workingTime.length > 0 &&
                            generalData.workingTime.map((elem, index) => (
                                <div className={styles.promoContacts} key={index}>
                                    <b>{isLang ? parse(elem.workingTimeDays) : parse(elem.workingTimeDaysEn)}</b>
                                    <span>{parse(elem.workingTimeTime)}</span>
                                </div>
                            ))}
                        </div>
                        <div className={styles.contactCol}>
                            <h2 className='global-title-h5'>{labelAddress}</h2>
                            {sm ? (
                                <span>{isLang ? parse(generalData.address) : parse(generalData.addressEn)},</span>
                            ) : (
                                <span><a
                                    href={linkToMap}>{isLang ? parse(generalData.address) : parse(generalData.addressEn)}</a>,</span>
                            )
                            }

                            {generalData && generalData.phoneList && generalData.phoneList.length > 0 && (
                                <nav aria-label={labelPhones}>
                                    {generalData.phoneList.map(elem => (
                                        <a
                                            href={`tel:${parse(elem.phoneListNumber.replaceAll('-', '').replaceAll(' ', ''))}`}
                                            key={elem.phoneListNumber}
                                        >
                                            {parse(elem.phoneListNumber)}
                                        </a>
                                    ))}
                                </nav>
                            )}
                            {generalData && generalData.socialList && generalData.socialList.length > 0 && (
                                <nav className={styles.promoSocial} aria-label={labelSocial}>
                                    {generalData.socialList.map(elem => {
                                        const elemClass = elem.socialListLink.title.includes('adviser') ? styles.tripadviser : styles.socialItem;
                                        return (
                                            <a
                                                href={isLang ? elem.socialListLink.url : elem.socialListLinkEn.url}
                                                key={isLang ? elem.socialListLink.url : elem.socialListLinkEn.url}
                                                {...{
                                                    target: '_blank',
                                                }}
                                            >
                                                <SVG
                                                    className={elemClass}
                                                    src={elem.socialListIcon.localFile.publicURL}
                                                    width={35}
                                                    height={35}
                                                    title={isLang ? elem.socialListLink.title : elem.socialListLinkEn.title}/>
                                            </a>
                                        )
                                    })}
                                </nav>
                            )}
                        </div>
                    </div>
                </div>
                <div className={styles.imgCol}>
                    <GatsbyImage
                        loading='lazy'
                        image={slideImage}
                        alt='Contact Img'
                    />
                </div>
            </div>
        </section>
    )
}

export default ContactUs
