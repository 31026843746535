import React from 'react'
import {graphql, useStaticQuery} from 'gatsby'
import Delivery from '../components/Delivery'

const DeliveryPage = ({data, isLang}) => {
    const {delivery} = data

    return (
        <>
            <Delivery data={delivery} isInner={true}/>
        </>
    )
}

export default DeliveryPage
