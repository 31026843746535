import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import ReactHtmlParser from 'html-react-parser'

import * as styles from './instagram.module.scss'

const Instagram = props => {
    const {
        instagramTitle,
        instagramContent,
        instagramLink: { instagramLinkTitle, instagramLinkSubtitle, instagramLinkLink, instagramLinkIcon },
        photoType,
        photoList
    } = props.data
    // const feedDataInstagramm = useStaticQuery(graphql`
    //     query InstagramQuery {
    //         allInstagramContent(limit: 8) {
    //             edges {
    //                 node {
    //                     localImage {
    //                         childImageSharp {
    //                             gatsbyImageData(layout: FIXED, width: 285, height: 285, quality: 90)
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // `)

    //let arrayOfInstaImages = photoType ? photoList : feedDataInstagramm.allInstagramContent.edges
    let arrayOfInstaImages = photoList

    const linkIcon = instagramLinkIcon && instagramLinkIcon.localFile ? getImage(instagramLinkIcon.localFile) : null
    return (
        <section className={styles.wrapper + ' global-container'}>
            <div className={styles.column}>
                {instagramTitle && <h2 className='global-title-h1-sm'>{ReactHtmlParser(instagramTitle)}</h2>}
                {instagramContent && <p className='global-title-h5'>{instagramContent}</p>}
                {instagramLinkTitle && instagramLinkLink && (
                    <a href={instagramLinkLink} target='__blank' className={styles.link}>
                        {typeof linkIcon === 'object' && linkIcon !== null && (
                            <div className={styles.linkIcon}>
                                <GatsbyImage image={linkIcon} alt='Link image' />
                            </div>
                        )}
                        <span>
                            <span className={styles.linkTitle}>{instagramLinkTitle}</span>
                            {instagramLinkSubtitle && <span>{instagramLinkSubtitle}</span>}
                        </span>
                    </a>
                )}
            </div>
            <div className={styles.column}>
                {arrayOfInstaImages && arrayOfInstaImages.length > 0 && (
                    <ul className={styles.feed}>
                        {arrayOfInstaImages.map((item, i) => {
                            //const image = photoType ? item.localFile ? getImage(item.localFile) : null : item.node && item.node.localImage ? getImage(item.node.localImage) : null

                            const image = item.localFile ? getImage(item.localFile) : null

                            if (!image) return null

                            return (
                                <li key={i} className={styles.feedItem}>
                                    <GatsbyImage
                                        className={styles.image}
                                        image={image}
                                        // loading='lazy'
                                        alt='Instagram image'
                                    />
                                </li>
                            )
                        })}
                    </ul>
                )}
            </div>
        </section>
    )
}

export default Instagram
